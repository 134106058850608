@import '../App/variables';

:global(.cta-button),
.button {
  $borderColor: #535353;

  background: rgba(15,15,15,0.56);
  border: 1px solid $borderColor;
  box-shadow: 5px 4px 7px 0 rgba(0,0,0,0.30);
  border-radius: 1px;

  min-width: 200px;
  min-height: 55px;
  box-sizing: border-box;
  padding: 15px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  margin-bottom: 0.5em;

  font-family: $secondaryFont;
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0;
  text-align: center;

  &:hover {
    border-color: lighten($borderColor, 10%);
  }
  &:focus {
    outline: 2px solid #000;
  }

  &:global(.cta-button),
  &.highlight {
    $borderColor: #EA5C1C;
    border-width: 2px;
    border-color: $borderColor;
    font-weight: 400;

    &:link,
    &:visited,
    &:active {
      color: $borderColor;
    }

    &:hover {
      border-color: lighten($borderColor, 10%);
      color: lighten($borderColor, 10%);
    }
  }

  &:not(.noTextTransform) {
    text-transform: uppercase;
  }

  &, a {
    &:link, &:visited, &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
}


:global(.cta-button:not(.cta-button-auto-width)) {
  width: 100%;
}
