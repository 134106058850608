@import '../App/variables';

:global(.watWeDoenPage) {
  @media (min-width: 760px) {
    .contentItem.contentItem {
      .primary {
        padding: 20px 6vw;
      }
    }
  }

}


h1.primaryTitle {
  opacity: .2;
  font-size: 50px;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: $mobileBreakpoint) {
    padding-right: 20px;
    font-size: 100px;
  }
}

.afterContentItem {
  @media (min-width: $mobileBreakpoint) {
    padding-left: 10px;
    margin-left: 50%;
  }

  :global(.centered) {
    text-align: center;
    padding-bottom: 3em;
  }
}

.contentItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2em;

  h2 {
    font-size: 34px;
    letter-spacing: -0.6px;
    margin-bottom: 0.5em;
  }

  h2, h2, h3, h4, h5, h6 {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    color: #fff;
  }

  .primaryTitle,
  .primary,
  .secondary {
    // By default, span the entire row (mobile first).
    flex: 1 1 100%;
    box-sizing: border-box;
  }

  .secondary {
    padding: 20px 0 0 0;
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: 760px) {
    & {
      justify-content: flex-end;
    }
    .primary,
    .secondary {
      // On bigger screens, multi column layout
      flex-basis: 50%;
    }
    &.hasNoImage {
      // If there is no image content, then the page title can be
      // next to the (first) content.
      .primaryTitle {
        flex-basis: 50%;
      }
      // The primary element should not be allowed to grow, so that
      // if it wraps (due to a long title / long content), it'll
      // still be aligned at the flex end.
      .primary {
        flex-grow: 0;
      }
      // The secondary element is hidden in LTR layouts so that the
      // content item, if it wraps, does not have an empty element
      // to the right. On RTL layout, that's what we want, so we leave it.
      &:not(.rtl) .secondary {
        display: none;
      }
    }
    .primary {
      // Default, put the text at the end (except in RTL layouts)
      order: 3;
      padding-left: 10px;
    }
    .secondary  {
      padding-right: 10px;
    }
    &.rtl .primary {
      padding-right: 10px;
      padding-left: 0;
    }
    &.rtl .secondary {
      // Put the image even further towards the end.
      order: 9;
      padding-right: 0;
      padding-left: 10px;
    }

    &.fullWidth {
      .primary,
      .secondary {
        padding-left: 0;
        padding-right: 0;
        flex-basis: 100%;
      }
      .secondary {
        order: 9;
      }
    }
  }

  &.hasPageTitle {}
  &.rtl {
    // Right to left
    // flex-direction: row;
    .secondary {
      order: 4;
    }
  }
  &.fullWidth {
    .primaryTitle, .secondary, .primary {
      flex: 1 1 100%;
    }
  }
}

.ContentPage {
  padding: 110px 80px;

  h1 {
    font-size: 100px;
    width: calc(50% - 25px);
    opacity: 0.6;
    display: inline-block;

    // Deze titel moet nog een effect krijgen
  }
}

.halfBlock {
  width: calc(50% - 20px);
  display: inline-block;
}

.contentBlocks {
  .block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .image, .text {
      width: calc(50% - 25px);

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-height: 450px;
      }
    }
    .text {
      display: flex;
      align-items: center;
    }
  }
}

input.text {
  background: #000;
  padding: 5px 20px 5px 20px;
  min-width: 250px;
  color: #fff;
  text-transform: none;

  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 40px;
  line-height: 2;
  outline: 0;
  margin-bottom: 15px;
  font-family: $primaryFont;
  font-size: 13px;
  transition: min-width 0.5s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

button.submit {
  background: #000;
  padding: 5px 20px 5px 20px;
  min-width: 125px;
  color: #fff;
  text-transform: none;

  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 40px;
  line-height: 2;
  outline: 0;
  margin-bottom: 15px;
  cursor: pointer;
  font-family: $primaryFont;
  font-size: 13px;
  transition: min-width 0.5s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.contactForm {
  label {
    display: inline-block;
    width: 150px;
    vertical-align: top;
  }

  .reqMsg {
    padding-left: 2ch;
  }

  .errMsg {
    font-weight: bold;
    display: block;
    color: red;
  }

  .textarea {
    background: #000;
    padding: 5px 20px 5px 20px;
    min-width: 250px;
    min-height: 250px;
    color: #fff;
    text-transform: none;

    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 40px;
    line-height: 2;
    outline: 0;
    margin-bottom: 15px;
    font-family: $primaryFont;
    font-size: 13px;
    transition: min-width 0.5s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
